import React from "react";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

const HeaderNav = ({ user, token }) => {
  return (
    <ul className="header-learn__nav nav">
      <li>
        <NavLink to="/" className="nav__link">
          Головна
        </NavLink>
      </li>
      <li>
        <NavLink to="/started_learn" className="nav__link">
          Тести
        </NavLink>
      </li>
      {/* {token && user.premium_check && (
        <>
          <li>
            <NavLink to="/theory" className="nav__link">
              Теорія
            </NavLink>
          </li>
          <li>
            <NavLink to="/reiteration" className="nav__link">
              Повторення
            </NavLink>
          </li>
        </>
      )} */}
      <li>
        <NavLink to="/about" className="nav__link">
          Про нас
        </NavLink>
      </li>
      {!token && (
        <li>
          <NavLink to="/login" className="nav__link">
            Увійти/Реєстрація
          </NavLink>
        </li>
      )}
    </ul>
  );
};

export default HeaderNav;
