import { all, takeLatest } from "redux-saga/effects";
import types from "../redux/types/index";
// ------------ IMPORT WATCHERS -------------------
import { watchLogin, watchLogout, watchGetUser } from "./auth";

import { watchGetConfig, watchGetSubscribe, watchGetThemes } from "./general";

import { watchSendBadResult, watchSendResult } from "./result";

import {
  watchGetQuestions,
  watchGetAllQuestions,
  watchGetExamQuestions,
  watchGetQuestionsFromSession,
  watchGetCrypt,
  watchGetRetestQuestions,
  watchGetBlockQuestions,
} from "./test";

import { watchGetTests } from "./myTests";

import { watchGetTerm } from "./agreement";

import { watchGetBlocks, watchGetTotalProgress } from "./map";
import { watchRecover } from "./recover";
import { watchResetPassword } from "./resetPassword";
import {
  watchRegister,
  watchRegisterBuyPremium,
  watchGetCategories,
  watchGetGroup,
  watchGetSchool,
  // watchRegisterPremium,
} from "./register";

import {
  watchGetFeedback,
  watchGetFreePremium,
  watchGetFreePremiumSubscribe,
} from "./main";

import { watchGetAbout } from "./about";

import { watchResetStatistics } from "./progress";

export function* rootSaga() {
  yield all([
    takeLatest(types.auth.LOGIN_REQUEST, watchLogin),
    takeLatest(types.auth.LOGOUT_REQUEST, watchLogout),
    takeLatest(types.auth.GET_USER_REQUEST, watchGetUser),

    takeLatest(types.general.GET_THEMES_REQUEST, watchGetThemes),
    takeLatest(types.general.GET_PREMIUM_SUBSCRIBE, watchGetSubscribe),
    takeLatest(types.general.GET_CONFIG, watchGetConfig),

    takeLatest(types.result.SEND_RESULT_REQUEST, watchSendResult),
    takeLatest(types.result.SEND_BAD_RESULT_REQUEST, watchSendBadResult),

    takeLatest(types.test.GET_QUESTIONS_REQUEST, watchGetQuestions),
    takeLatest(types.test.GET_ALL_QUESTIONS_REQUEST, watchGetAllQuestions),
    takeLatest(types.test.GET_EXAM_QUESTIONS_REQUEST, watchGetExamQuestions),
    takeLatest(
      types.test.GET_RETEST_QUESTIONS_REQUEST,
      watchGetRetestQuestions
    ),
    takeLatest(types.test.GET_BLOCK_QUESTIONS_REQUEST, watchGetBlockQuestions),
    takeLatest(
      types.test.GET_QUESTIONS_FROM_SESSION_REQUEST,
      watchGetQuestionsFromSession
    ),
    takeLatest(types.test.GET_CRYPT_REQUEST, watchGetCrypt),

    takeLatest(types.myTests.GET_TESTS_REQUEST, watchGetTests),

    takeLatest(types.agreement.AGREEMENT_TERM, watchGetTerm),

    // takeLatest(types.register.REGISTER_REQUEST, watchRegister),

    takeLatest(types.map.GET_BLOCKS_REQUEST, watchGetBlocks),
    takeLatest(types.map.GET_TOTAL_PROGRESS_REQUEST, watchGetTotalProgress),

    takeLatest(types.recover.RECOVER_REQUEST, watchRecover),

    takeLatest(types.resetPassword.RESET_PASSWORD_REQUEST, watchResetPassword),

    takeLatest(types.register.REGISTER_REQUEST, watchRegister),
    takeLatest(types.register.REGISTER_BUY_REQUEST, watchRegisterBuyPremium),
    takeLatest(types.register.REGISTER_CATEGORIES_REQUEST, watchGetCategories),
    takeLatest(types.register.REGISTER_SCHOOL_REQUEST, watchGetSchool),
    takeLatest(types.register.REGISTER_GROUP_REQUEST, watchGetGroup),

    takeLatest(types.main.GET_FREE_PREMIUM_REQUEST, watchGetFreePremium),
    takeLatest(types.main.GET_FEEDBACK_REQUEST, watchGetFeedback),
    takeLatest(
      types.main.GET_FREE_PREMIUM_SUBSCRIBE_REQUEST,
      watchGetFreePremiumSubscribe
    ),

    takeLatest(types.about.ABOUT_PAGE_REQUEST, watchGetAbout),

    takeLatest(types.progress.PROGRESS_REQUEST, watchResetStatistics),
  ]);
}
