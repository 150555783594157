import sprites from "../../../assets/img/sprites.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import "./progress-action.scss";

const ProgressAction = ({
  text,
  iconColor,
  icon,
  action,
  setOpenModalReset,
}) => {
  const history = useHistory();

  const handlePage = () => {
    if (action === "redirect") {
      history.push("/reiteration");
    }

    if (action === "reset") {
      setOpenModalReset(true);
      document.body.classList.add("open");
    }
  };

  return (
    <button className="progress-action" onClick={handlePage}>
      {text}
      <svg
        className={`progress-action__icon progress-action__icon--${iconColor}`}
      >
        <use xlinkHref={`${sprites}#${icon}`}></use>
      </svg>
    </button>
  );
};

export default ProgressAction;
