import React, { useEffect, useState } from "react";
import Sidebar from "../../hoc/sidebar";
import { NavLink, useHistory } from "react-router-dom";
import ModalHint from "../../common/Modal/ModalHint";
import Hint from "../../images/Hint";
import ModalBad from "../../hoc/modalBad";
const Test = (props) => {
  // ---------------- GET PROPS -------------------------
  const {
    questions,
    token,
    sendResult,
    getQuestionsFromSession,
    onSetThemes,
    onSetCurrentTheme,
    onSetQuestions,
    sendBadResult,
    empty,
    setLogin,
    sendSuccess,
    success,
  } = props;
  const answers = JSON.parse(sessionStorage.getItem("answers"));
  // ---------------- GET TYPE TEST ------------------
  const general = JSON.parse(sessionStorage.getItem("general"));
  // --------------- GET USER --------------------------------
  const user = JSON.parse(sessionStorage.getItem("user"));
  // -------------- GET TIME --------------------
  const currentTime = JSON.parse(sessionStorage.getItem("timer"));
  // -------------- GET ANSWERS ----------------------
  const currentAnswers = JSON.parse(sessionStorage.getItem("answers"));
  // ------------ GET CURRENT NUMBER ------------------------
  const currNumbSess = JSON.parse(sessionStorage.getItem("current"));
  // ------------ GET CURRENT RESULT --------------------------
  const currResSess = JSON.parse(sessionStorage.getItem("result"));
  // ------------- GET GREEN QUESTIONS ----------------------
  const greenQ = JSON.parse(sessionStorage.getItem("greenQ"));
  // ------------- GET RED QUESTIONS ----------------------
  const redQ = JSON.parse(sessionStorage.getItem("redQ"));

  // const results = JSON.parse(sessionStorage.getItem("result"));
  // ----------------- GET LOCATION --------------------
  const location = useHistory(null);
  // ----------------- SET CURRUNT QUESTION ---------------
  const [currQuest, setCurrQuest] = useState(null);
  // ----------------- SET CURRENT ANSWER ---------------
  const [answer, setAnswer] = useState("");
  // ----------------- SET CURRENT NUMBER ----------------
  const [number, setNumber] = useState(null);
  // ----------------- SET STATE FOR COMMON QUIESTION ------------------
  const [commonQuest, setCommonQuest] = useState(null);
  // ----------------- SET CURRENT NUMBER ----------------
  const [currNumb, setCurrNumb] = useState(1);
  // ----------------- SET ARRAY ANSWERS -------------
  const [arrAnswers, setArrAnswers] = useState([]);
  // --------------- SET COUNTER -----------------
  const [counter, setCounter] = useState(20 * 60);
  const [counterText, setCounterText] = useState(null);
  // -------------- SET ALL INFO ABOUT TEST ------------
  const [info, setInfo] = useState([]);
  // -------------- SET RESULT --------------------
  const [countResult, setCountResult] = useState([]);
  // ------------ SET ARRAY QUESTIONS --------------
  const [questArray, setQuestArray] = useState([]);
  // -------------- SET CORRECT QUESTIONS ----------------
  const [correctQuestions, setCorrectQuestions] = useState([]);
  // -------------- SET UNCORRECT QUESTIONS ---------------
  const [uncorrectQuestions, setUncorrectQuestions] = useState([]);
  // -------------- SET MODAL HINT ---------------
  const [modalHint, setModalHint] = useState(false);
  // -------------- SET CORRECTA ANIMATION ---------------
  const [click, setClick] = useState(false);
  // -------------- SET AUTOSCROLL ---------------
  const [scroll, setScroll] = useState(null);
  // -------------- SET LEFT TIME ---------------
  const [time, setTime] = useState(null);
  // -------------- SET MODAL BAD ---------------
  const [modalBad, setModalBad] = useState(false);
  // -------------- SET EMPTY DATA ---------------
  const [emptyData, setEmptyData] = useState(false);

  // const [hintShowed, setHintShowed] = useState(false);
  const hintShowedData = JSON.parse(localStorage.getItem("hint"));

  useEffect(() => {
    if (!token) {
      setLogin(sessionStorage.getItem("token"));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (empty) {
      setEmptyData(true);
    }
    // eslint-disable-next-line
  }, []);
  // ---------------- SET FOR NOT QUESTIONS -------------------
  useEffect(() => {
    if (emptyData) {
      history.push("/learn");
      setEmptyData(false);
    }
    // eslint-disable-next-line
  }, [emptyData]);

  useEffect(() => {
    if (questions?.length === 0) {
      history.push("/learn");
      resetTest();
    }
    // eslint-disable-next-line
  }, [questions]);

  // ---------------- SET MODAL BAD -------------------
  const correctCount = answers?.filter(
    (answer) => answer.correct === 1
  )?.length;
  const getModalBad = () => {
    if (
      (general.type === "theme" || general.type === "retest") &&
      correctCount !== answers?.length
    ) {
      setModalBad(true);
    } else {
      location.push("/learn");
    }
  };

  // ---------------- SET QUESTIONS -------------------
  useEffect(() => {
    if (questions !== null && questions.length > 0 && token) {
      setCommonQuest(questions);
      if (currNumbSess) {
        const getQue = questions.filter((q) => q.id === currNumbSess.id);
        setCurrNumb(currNumbSess.number);
        setNumber(currNumbSess.id);
        setCurrQuest(getQue[0]);
      } else {
        setCurrQuest(questions[0]);
        setNumber(questions[0].id);
        setCurrNumb(1);
      }
    }
    // eslint-disable-next-line
  }, [questions, token, currNumbSess]);

  // -------------- SET CHECKED QUESTION ------------------
  const onChangeQuestion = (id, index) => {
    const current = commonQuest?.filter((q) => q.id == id);
    const checked = current[0]?.answers?.filter((a) =>
      arrAnswers.includes(a.id)
    );
    if (info?.length === commonQuest?.length) {
      setCurrQuest(...current);
      setCurrNumb(index + 1);
      setNumber(current[0].id);
      sessionStorage.setItem(
        "current",
        JSON.stringify({ number: index + 1, id: current[0].id })
      );
    }
    if (checked.length !== 0) {
      return;
    }
    setCurrQuest(...current);
    setCurrNumb(index + 1);
    setNumber(current[0].id);
    sessionStorage.setItem(
      "current",
      JSON.stringify({ number: index + 1, id: current[0].id })
    );
  };

  // ------------- SET CHECKED ANSWERS ------------------
  const onChangeAnswers = (element) => {
    setAnswer(element.id);
  };

  const setColorAnswers = (current, numberTop, arrNumb) => {
    setCurrQuest(current);
    setCurrNumb(numberTop);
    setNumber(current?.length === 0 ? 1 : current?.id);
    sessionStorage.setItem(
      "current",
      JSON.stringify({
        number: numberTop,
        id: current?.length === 0 ? 1 : current?.id,
      })
    );
    // ------------ SET COLOR ------------------
    if (arrNumb[0].correct_answer === 1) {
      setCorrectQuestions([...correctQuestions, currQuest.id]);
      sessionStorage.setItem(
        "greenQ",
        JSON.stringify([...correctQuestions, currQuest.id])
      );
    } else {
      setUncorrectQuestions([...uncorrectQuestions, currQuest.id]);
      sessionStorage.setItem(
        "redQ",
        JSON.stringify([...uncorrectQuestions, currQuest.id])
      );
    }
  };

  const resetTestsData = () => {
    setCorrectQuestions([]);
    setUncorrectQuestions([]);
    setCurrQuest(null);
    setCurrNumb(null);
    setNumber(null);
    setCommonQuest(null);
    setInfo([]);
    setCountResult([]);
    setArrAnswers([]);
    setQuestArray([]);
    onSetThemes(null);
    onSetCurrentTheme(null);
    onSetQuestions(null);
    getModalBad();
  };

  // ----------------- CHECK ANSWER -----------------
  const checkAswer = (e) => {
    e.preventDefault();
    const arrNumb = currQuest?.answers.filter((q) => q.id === answer);
    if (!answer) {
      return;
    }
    if (!currQuest) {
      return;
    }
    if (arrNumb.length === 0) {
      return;
    }
    // -------------- SET QUESTION_ID TO ARRAY -----------
    setQuestArray([...questArray, arrNumb[0].question_id]);
    // --------------- TO NEXT NUMBER --------------------
    let numberTop = currNumb + 1;
    let current = commonQuest?.find(
      (q, index) => index + (numberTop - currNumb) === numberTop
    );
    let nextQuest = current?.answers.filter((q) => arrAnswers.includes(q.id));

    if (nextQuest?.length > 0 || commonQuest.length === currNumb) {
      const findQuest = commonQuest.find((q) => !questArray.includes(q.id));
      if (findQuest === undefined) {
        current = commonQuest[0];
        numberTop = 1;
      } else {
        if (findQuest.id === currQuest.id) {
          current = commonQuest.find(
            (q) => !questArray.includes(q.id) && q.id !== currQuest.id
          );
          numberTop = commonQuest.indexOf(current) + 1;
        } else {
          current = findQuest;
          numberTop = commonQuest.indexOf(current) + 1;
        }
      }
    }

    // ----------------- SET ALL ANSWERS TO INFO ARRAY ----------------
    const allInfo = [...info];

    if (
      (general?.type === "retest" || general.type === "theme") &&
      arrNumb[0].correct_answer === 0
    ) {
      setTimeout(() => {
        setColorAnswers(current, numberTop, arrNumb);
        if (allInfo?.length === commonQuest?.length) {
          setClick(true);
        }
      }, 2500);
    } else if (
      (general.type === "retest" || general.type === "theme") &&
      arrNumb[0].correct_answer === 1
    ) {
      setColorAnswers(current, numberTop, arrNumb);
      setTimeout(() => {
        if (allInfo?.length === commonQuest?.length) {
          setClick(true);
        }
      }, 100);
    } else {
      setColorAnswers(current, numberTop, arrNumb);
    }

    // ----------------- SET ANSWER TO ARRAY ANSWERS -------------------------
    setArrAnswers([...arrAnswers, answer]);
    // ----------------- SET CORRECT ANSWER TO RESULT -----------------
    if (arrNumb[0].correct_answer === 1) {
      setCountResult([...countResult, 1]);
    }
    arrNumb.map((a) => {
      allInfo.push({
        answer_id: a.id,
        question_id: a.question_id,
        correct: a.correct_answer,
      });
    });
    setInfo(allInfo);
    sessionStorage.setItem("answers", JSON.stringify(allInfo));
    setAnswer("");
    setScroll(true);

    // ------------------- SEND RESULT ---------------
    if (allInfo?.length === commonQuest?.length) {
      if (arrNumb && general?.type === "exam") {
        sessionStorage.setItem(
          "result",
          JSON.stringify({
            result: [...countResult, 1],
            count: commonQuest?.length,
          })
        );
      }

      if (general?.type === "exam") {
        sessionStorage.setItem(
          "current",
          JSON.stringify({
            number: 1,
            id: commonQuest[0].id,
          })
        );
      }

      let leftTime = 20 * 60 - counter;
      setTime(leftTime);

      if (!click) {
        const dataInfo = {
          type: general.type,
          theme_id: general.theme,
          time: leftTime,
          data_question: JSON.stringify(allInfo),
        };

        if (general.type === "exam") {
          location.push("/result");
          sendResult({
            info: dataInfo,
            token,
          });
        }

        if (general.type === "all_theme") {
          sendResult({
            info: dataInfo,
            token,
          });
          resetTestsData();
        }
      }
    }
  };

  //   ---------------- SEND RESULT AFTER LAST BLINK ANSWER ----------------
  useEffect(() => {
    if (click) {
      const infoData = {
        type: general.type,
        theme_id: general.theme,
        idx: general.block,
        time: time,
        data_question: JSON.stringify(info),
      };

      if (general.type === "retest") {
        sendBadResult({
          info: infoData,
          token,
        });
      }

      if (general.type === "theme") {
        sendResult({
          info: infoData,
          token,
        });
      }

      setClick(false);
      resetTestsData();
    }
    // eslint-disable-next-line
  }, [click]);

  //   ---------------- KEEP TRACK TIMER ----------------
  useEffect(() => {
    const timer =
      counter > 0 &&
      setInterval(function () {
        if (general?.type === "theme" || general?.type === "retest") {
          return () => clearInterval(timer);
        }
        if (info?.length === commonQuest?.length) {
          return () => clearInterval(timer);
        }

        let seconds = counter % 60;
        let minuts = (counter / 60) % 60;
        let strTimer = `${
          minuts < 10 ? `0${Math.trunc(minuts)}` : Math.trunc(minuts)
        }:${seconds < 10 ? `0${seconds}` : seconds}`;
        setCounterText(strTimer);
        sessionStorage.setItem("timer", JSON.stringify(counter));
        setCounter(counter - 1); // Уменьшаем таймер
      }, 1000);

    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, [counter]);

  // --------------- KEEP TRACK FINISHED TIME ----------------
  useEffect(() => {
    if (counter === 0) {
      const dataInfo = {
        type: general.type,
        theme_id: general.theme,
        time: "20:00",
        data_question: JSON.stringify(info),
      };
      const dataInfoWithIdx = { ...dataInfo, idx: general.block };

      if (general.type === "exam") {
        location.push("/result");
        sendResult({
          info: dataInfo,
          token,
        });
      }

      if (general.type === "all_theme") {
        sendResult({
          info: dataInfo,
          token,
        });
        resetTestsData();
      }

      if (general.type === "retest") {
        sendBadResult({
          info: dataInfoWithIdx,
          token,
        });
        resetTestsData();
      }

      if (general.type === "theme") {
        sendResult({
          info: dataInfoWithIdx,
          token,
        });
        resetTestsData();
      }
    }
    // eslint-disable-next-line
  }, [counter]);

  // -------------------- CHECK TIMER -----------------
  useEffect(() => {
    if (currentTime) {
      setCounter(currentTime);
    }
    // eslint-disable-next-line
  }, []);
  // ------------------ CHECK ANSWERS ---------------
  useEffect(() => {
    if (currentAnswers) {
      setInfo(currentAnswers);
      const arr = [];
      currentAnswers.map((c) => arr.push(c.answer_id));
      setArrAnswers(arr);
    }
    // eslint-disable-next-line
  }, []);
  // ------------- CHECK RESULT -----------------
  useEffect(() => {
    if (currResSess) {
      setCountResult(currResSess.result);
    }
    // eslint-disable-next-line
  }, []);
  // ------------ CHECK QUESTIONS COLORS -------------
  useEffect(() => {
    if (greenQ && !redQ) {
      setCorrectQuestions(greenQ);
      setUncorrectQuestions([]);
      setQuestArray(greenQ);
    } else if (redQ && !greenQ) {
      setCorrectQuestions([]);
      setUncorrectQuestions(redQ);
      setQuestArray(redQ);
    } else if (greenQ && redQ) {
      setCorrectQuestions(greenQ);
      setUncorrectQuestions(redQ);
      setQuestArray(greenQ.concat(redQ));
    }
    // eslint-disable-next-line
  }, []);
  // --------------- GET QUESTIONS -----------------
  window.onload = () => {
    if (questions === null && token) {
      getQuestionsFromSession(token);
    }
  };
  // ------------- SET RESULT ----------------
  useEffect(() => {
    if (countResult && commonQuest) {
      sessionStorage.setItem(
        "result",
        JSON.stringify({ result: countResult, count: commonQuest?.length })
      );
    }
    // eslint-disable-next-line
  }, [countResult]);

  const history = useHistory();
  // ------------- RESET TEST -------------
  const resetTest = () => {
    onSetThemes([]);
    onSetCurrentTheme(0);
    onSetQuestions([]);
    sessionStorage.removeItem("timer");
    sessionStorage.removeItem("answers");
    sessionStorage.removeItem("current");
    sessionStorage.removeItem("result");
    sessionStorage.removeItem("redQ");
    sessionStorage.removeItem("greenQ");
    sessionStorage.removeItem("general");
  };

  // ------------- HINT -------------
  const notes = currQuest?.notes
    ?.replace("../../../", process.env.REACT_APP_API_URL)
    .replace("../../", process.env.REACT_APP_API_URL);

  // -------------- OPEN MODAL HINT ----------------
  const onModalHint = () => {
    setModalHint(true);
    localStorage.setItem("hint", JSON.stringify("true"));
  };

  // -------------- SET AUTOSCROLL ----------------
  useEffect(() => {
    if (scroll) {
      document.querySelector(".row").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      setScroll(false);
    }
    // eslint-disable-next-line
  }, [scroll]);

  // SET WHEEL EVENT
  useEffect(() => {
    const numbers = document.querySelector(".footer__nav");

    if (numbers) {
      numbers.addEventListener("wheel", (event) => {
        let modifier;
        if (event.deltaMode == event.DOM_DELTA_PIXEL) {
          modifier = 1;
        } else if (event.deltaMode == event.DOM_DELTA_LINE) {
          modifier = parseInt(getComputedStyle(event.target).lineHeight);
        } else if (event.deltaMode == event.DOM_DELTA_PAGE) {
          modifier = event.target.clientHeight;
        }

        if (event.deltaY != 0) {
          // зміна вертикальної прокрутки на горизонтальну
          event.target.scrollLeft += modifier * event.deltaY;
          event.preventDefault();
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  const handleHintShowed = () => {
    if (!hintShowedData) {
      return "puls puls--hint";
    } else {
      return "";
    }
  };

  return (
    <div className="row">
      {modalHint && <ModalHint setModalHint={setModalHint} notes={notes} />}
      {modalBad && <ModalBad setModalBad={setModalBad} />}
      <Sidebar />
      <div className="content">
        <div className="content__inner">
          <header className="header">
            {user?.premium_check && (
              <div className="header__name page-block">
                <p className="header__text">Зараз здає:</p>
                <p className="header__highliht">
                  {`${user?.first_name} ${user?.last_name}`}
                </p>
              </div>
            )}
            <div className="header__progress">
              {arrAnswers && (
                <p className="header__text">
                  Пройдено на{" "}
                  <span className="header__percent">
                    {Math.round(
                      (arrAnswers?.length * 100) / commonQuest?.length
                    )}
                    %
                  </span>
                </p>
              )}
              <div className="header__line">
                <span
                  style={{
                    width: `${Math.round(
                      (arrAnswers?.length * 100) / commonQuest?.length
                    )}%`,
                  }}
                  className="header__line-inner"
                ></span>
              </div>
              <div className="header__progress-number">
                <span className="header__number-current">{currNumb}</span>/
                {commonQuest?.length}
              </div>
            </div>
            <div className="header__timer page-block">
              {general?.type === "theme" || general?.type === "retest" ? (
                <p className="header__text">Таймер вимкнено</p>
              ) : (
                <>
                  <p className="header__text">Залишилось часу:</p>
                  <span className="time">{counterText}</span>
                </>
              )}
            </div>
          </header>
          <main className="test page-block">
            <form>
              <div className="test__top">
                <h1 className="test__title title title--sm">
                  Питання {currNumb}
                </h1>
                {(general?.type === "theme" || general?.type === "retest") && (
                  <div>
                    {currQuest?.notes ? (
                      <div
                        className={`hint ${handleHintShowed()}`}
                        onClick={onModalHint}
                      >
                        <Hint />
                        <div className="hint-show"></div>
                      </div>
                    ) : (
                      <div className="hint disabled">
                        <Hint />
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="test__item">
                <p className="test__question">{currQuest?.question}</p>
                <div className="test__img">
                  {currQuest?.image?.length > 0 && (
                    <img
                      src={`${process.env.REACT_APP_API_URL}images/question/${currQuest?.image}`}
                      alt={`${currQuest?.question}`}
                    />
                  )}
                </div>
              </div>
              <div className="test__inputs">
                {currQuest &&
                  currQuest?.answers?.map((t, index) => {
                    if (
                      arrAnswers?.includes(t.id) &&
                      t.correct_answer !== 1 &&
                      general?.type !== "exam"
                    ) {
                      currQuest?.answers
                        .filter((answer) => {
                          return answer.correct_answer;
                        })
                        .map((answer) => {
                          document
                            .querySelector("input")
                            ?.setAttribute("disable", "true");
                          const label = document
                            .querySelector('input[value="' + answer.id + '"')
                            ?.parentElement.querySelector("label");
                          label?.classList.add("footer__btn--true");
                          label?.classList.add("correct");
                        });
                    }
                    return (
                      <div key={t.id} className="test__radio">
                        <input
                          className="visually-hidden test__input"
                          type="radio"
                          name="answers"
                          id={t.answer}
                          checked={arrAnswers.includes(t.id) || t.id === answer}
                          onChange={() => onChangeAnswers(t)}
                          value={t.id}
                          disabled={info?.length === commonQuest?.length}
                        />
                        <label
                          htmlFor={t.answer}
                          className={`input__radio ${
                            arrAnswers?.includes(t.id)
                              ? t.correct_answer === 1
                                ? "footer__btn--true"
                                : "footer__btn--false"
                              : ""
                          } ${
                            info?.length === commonQuest?.length
                              ? t.correct_answer === 1
                                ? "footer__btn--true"
                                : ""
                              : ""
                          }`}
                        >
                          {t.answer}
                        </label>
                      </div>
                    );
                  })}
              </div>
              {info?.length === commonQuest?.length &&
              (general.type === "exam" || general.type === "all_theme") &&
              !click ? (
                <div className="result-btn-container">
                  <NavLink
                    to="/started_learn"
                    onClick={resetTest}
                    style={{ margin: "0 15px" }}
                  >
                    <button className="btn register__btn">На головну</button>
                  </NavLink>
                  {/* <p onClick={logoutUser}>
                    <button className="btn register__btn">Вийти з програми</button>
                  </p> */}
                </div>
              ) : (
                <button
                  className="btn btn--blue btn--sm btn--rounded-md test__btn"
                  onClick={checkAswer}
                  disabled={!answer}
                >
                  Далі
                </button>
              )}
            </form>
          </main>

          <footer className="footer">
            <nav className="footer__nav">
              {commonQuest &&
                commonQuest?.map((n, index) => {
                  return (
                    <div key={n.id} className="test__radio number_radio">
                      <input
                        className="visually-hidden test__input"
                        type="radio"
                        name="number"
                        id={n.id}
                        checked={number == n.id}
                        value={number}
                        onChange={() => onChangeQuestion(n.id, index)}
                      />
                      <label
                        htmlFor={n.id}
                        className={`footer__btn ${
                          correctQuestions?.includes(n.id)
                            ? "footer__btn--true"
                            : uncorrectQuestions?.includes(n.id)
                            ? "footer__btn--false"
                            : ""
                        }`}
                      >
                        {index + 1}
                      </label>
                    </div>
                  );
                })}
            </nav>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Test;
