import { combineReducers } from "redux";
import auth from "./auth";
import general from "./general";
import test from "./test";
import myTests from "./myTests";
import popup from "./popup";
import agreement from "./agreement";
// import register from "./register";
import map from "./map";
import recover from "./recover";
import resetPassword from "./resetPassword";
import register from "./register";
import main from "./main";
import about from "./about";
import progress from "./progress";

const rootReducer = combineReducers({
  auth,
  general,
  test,
  myTests,
  popup,
  agreement,
  // register,
  map,
  recover,
  resetPassword,
  register,
  main,
  about,
  progress,
});

export default rootReducer;
