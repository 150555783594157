import axios from "axios";
import { auth } from "./auth";
import { register } from "./register";
import { general } from "./general";
import { test } from "./test";
import { result } from "./result";
import { myTests } from "./myTests";
import { agreement } from "./agreement";
import { map } from "./map";
import { recover } from "./recover";
import { main } from "./main";
import { about } from "./about";
import { progress } from "./progress";

const URL = `${process.env.REACT_APP_API_URL}api/`;

export const axiosInstance = axios.create({
  baseURL: URL,
});

export const API = {
  auth,
  register,
  general,
  test,
  result,
  myTests,
  agreement,
  map,
  recover,
  main,
  about,
  progress,
};
